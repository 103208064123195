html,
body {
  background: #22211f;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 0.5rem;
}

.richard {
  width: 16rem;
  border-radius: 2px;
}

.text {
  font-size: 1rem;
  color: #f9f0df;
}
